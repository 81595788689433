import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"
import Slider from "react-slick";
import CTA from "../components/cta"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

export default class CoviDNAPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "CoviDNA")

    // load bing widget script
    const script = document.createElement("script");
    script.src = "//www.bing.com/widget/bootstrap.answer.js";
    script.async = true;
    document.body.appendChild(script);

  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Layout>
        <div id="covidna-lp">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="py-lg-5 bg-gray"
            id='covidna-banner'
          >
            <div className="container">
              <div className="row my-lg-5">
                <div className="col-lg-4 offset-lg-8 text-center text-lg-right content py-4">
                  <h1 className='h2'>Project CoviDNA</h1>
                  <p >A coronavirus report to evaluate your infection risk from a genetic level.</p>
                  <a
                    href='https://app.advanxhealth.com/survey/coronavirus'
                    className="btn btn-primary px-5"
                  >
                    <strong>Join Now</strong>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section
            className="py-0 bg-white"
          >
            <div className="container">
              <div className="row align-items-end">
                <div className="col-md-4 offset-md-1 my-5 py-5 text-center ">
                  <h3>Coronaviruses</h3>
                  <p >
                    They are a family of viruses that can cause mild to severe upper respiratory tract infection.
                    <br/><br/>
                    There are 7 types of human coronaviruses. Among those, 3 of them - namely MERS, 
                    SARS and COVID-19 - are shown to cause more severe illness
                  </p>
                </div>
                <div className='col-md-7'>
                  <img
                    className="img-fluid"
                    src={withPrefix("img/covidna/coronavirus-image.png")}
                    alt="Section Image"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            className="py-5 bg-gradient-dark"
          >
            <div className="my-3 container">
              <h3 className='text-center text-white'>What This Report Covers:</h3>
              <div className="mt-5 row">
                {
                  pageData.coronaviruses.map((coronavirus) => {
                    return (
                      <div className='col-md-4 d-flex align-items-stretch'>
                        <div key={coronavirus.title} className="card rounded-card px-3 my-3" >
                          <div className="card-body text-left">
                            <div className="card-title mt-3">
                            <p><span className='h3'>{coronavirus.title}</span> ({coronavirus.name})</p>
                            </div>
                              <p className='text-justify' dangerouslySetInnerHTML={{__html: coronavirus.description}}>
                              </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
          <section className='py-5' style={{backgroundImage: `url('/img/covidna/tracker-bg.png')`}}>
            <div className="container">
              <h2 className='text-center'>People Affected Across The Globe</h2>
              <div className="bingwidget mt-5 px-0 col-md-7 mx-auto" data-type="covid19_stats" data-market="en-us" data-language="en-us" data-app="bingwidget"></div>
            </div>
          </section>
          <section className='bg-primary-blue-light'>
            <div className="container">
              <div className='row align-items-center'>
                <div className='col-md-6 my-5'>
                  <Slider {...settings}>
                    <div>
                      <img src={withPrefix('img/covidna/study-1.png')} className="img-fluid" alt="Study 1"/>
                    </div>
                    <div>
                      <img src={withPrefix('img/covidna/study-2.png')} className="img-fluid" alt="Study 2"/>
                    </div>
                    <div>
                      <img src={withPrefix('img/covidna/study-3.png')} className="img-fluid" alt="Study 3"/>
                    </div>
                  </Slider>
                </div>
                <div className='col-md-6 px-md-4 my-5'>
                  <p><span className='h5 text-primary'>Current Research</span> is showing that a person's genetics can be predispose them to coronaviruses upon virus exposure.</p>
                  <p>Did you know? A certain variation in the gene can make you more susceptible towards the coronavirus and even cause you to have more severe symptoms than other people if you are exposed to the virus.</p>
                </div>
              </div>
            </div>
          </section>
          <section className='py-5'>
            <div className="container">
              <p className='text-center'>
                <img src={withPrefix('img/covidna/covidna-title.png')} className="img-fluid" alt="CoviDNA" style={{maxHeight: '200px'}}/>
              </p>
              <div className='row my-5 align-items-center'>
                <div className='col-md-5 text-justify'>
                  <p>
                    The Coronavirus Report will be part of Project CoviDNA, our very first internal research project initiative that we aim to conduct in the very near future.
                  </p>
                  <p>
                    We hope to study how your genomic predisposes you to the coronaviruses upon exposure and discover useful information that can help Malaysians in this battle against coronaviruses.
                  </p>
                  <p>
                    Join us to be a part of this Malaysian project!
                  </p>
                </div>
                <div className='col-md-6 offset-md-1 my-5'>
                  <div className="card shadow rounded-card px-3" >
                    <div className="card-body text-left">
                      <div className="card-title mt-3">
                        <p className='text-center h3 text-primary'>Our Objective</p>
                        <div className='row my-5 align-items-center'>
                          <div className='col-2'>
                            <p className='text-center h1 text-secondary'>1</p>
                          </div>
                          <div className='col-10'>
                            <p className='text-justify'>
                              To better understand how a person's genetic makes them more susceptible to the coronaviruses 
                              and the severity of the infection upon exposure to the virus.
                            </p>
                          </div>
                        </div>
                        <div className='row my-5 align-items-center'>
                          <div className='col-2'>
                            <p className='text-center h1 text-secondary'>2</p>
                          </div>
                          <div className='col-10'>
                            <p className='text-justify'>
                              To identify a specific genetic makeup among the population that makes them more susceptible to the coronaviruses.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className='text-center my-5'>
                <a
                  href="https://app.advanxhealth.com/survey/coronavirus"
                  className="btn btn-primary px-5 btn-lg"
                >
                  <strong className='h4 mb-0'>I Want In!</strong>
                </a>
              </p>
            </div>
          </section>
          <section
            className="py-5 bg-gradient"
          >
            <div className="container">
              <h3 className='text-center'>What This Report Covers:</h3>
              <div className='row my-4'>
                <p className='col-md-8 offset-md-2 lead text-center'>
                  With the available evidence and information sourced from the current research and the 
                  state-of-the-art lab facility at Advanx Health, you will receive:
                </p>
              </div>
              <div className="my-5 mx-md-5 row">
                <div className='col-md-4 p-5'>
                  <img src={withPrefix('img/covidna/include-1.png')} className="img-fluid" alt="Risk"/>
                </div>
                <div className='col-md-4 p-5'>
                  <img src={withPrefix('img/covidna/include-2.png')} className="img-fluid" alt="Severity"/>
                </div>
                <div className='col-md-4 p-5'>
                  <img src={withPrefix('img/covidna/include-3.png')} className="img-fluid" alt="Update"/>
                </div>
              </div>
            </div>
          </section>
          <section
            className="py-5 bg-gray"
          >
            <div className="container">
              <div className='mb-5'>
                <h5><span className='badge badge-danger badge-pill'>Disclaimer</span></h5>
                <p>
                  <span className='text-danger'>Opting in for this coronavirus report would equal to giving consent for the use of your data for future internal research puposes. </span>
                  In the event where your data is required for external research use, Advanx Health will obtain your consent prior to conducting the said research.
                </p>
                <p>
                  We would like to strongly emphasize that this report is based on the current research and is subject to change upon more research done in this field. 
                  We believe by providing what is currently available, our customer will benefit from it and be able to get some understanding on their genetic risk factor.
                </p>
                <p>
                  This document is not a medical cert or a diagnosis confirming you will definitely be at high risk or severely affected with the virus (depending on your results).
                </p>
                <p>
                  This report only covers your risk of contracting the three main coronaviruses namely SARS-CoV1, SARS-CoV2, and MERS-CoV. 
                </p>
              </div>
              <div>
                <h5><span className='badge badge-secondary badge-pill'>Precaution</span></h5>
                <p>
                  Be sure to always practice the health precaution set by the authorities to break the chain of transmission.<br/>
                  Practice social distancing, wear a face mask and wash your hands.
                </p>
              </div>
            </div>
          </section>
          <section
            className="py-5 bg-work"
          >
            <div className="container">
              <h3 className='text-center'>How It Works</h3>
              <div className="my-5 mx-md-5 row">
                <div className='col-md-3 p-4 my-4'>
                  <img src={withPrefix('img/covidna/step-1.png')} className="img-fluid" alt="Step 1"/>
                </div>
                <div className='col-md-3 p-4 my-4'>
                  <img src={withPrefix('img/covidna/step-2.png')} className="img-fluid" alt="Step 2"/>
                </div>
                <div className='col-md-3 p-4 my-4'>
                  <img src={withPrefix('img/covidna/step-3.png')} className="img-fluid" alt="Step 3"/>
                </div>
                <div className='col-md-3 p-4 my-4'>
                  <img src={withPrefix('img/covidna/step-4.png')} className="img-fluid" alt="Step 4"/>
                </div>
              </div>
            </div>
          </section>
          <section id="cta" className="cta bg-secondary-light">
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-lg-10 offset-lg-1 text-center">
                  <h2 className="text-center">Package For You:</h2>
                  <p>Uncover your genetic predispositions. Manage your lifestyle with personalised recommendations.</p>
                </div>
              </div>
              <CTA />
            </div>
          </section>
          <section
            className="bg-gradient-dark"
          >
            <div className="container">
              <h3 className='text-center text-white'>Frequently Asked Questions (F.A.Q)</h3>
              <div className="mt-5 row bg-gray">
                <Accordion allowZeroExpanded='true'>
                  {pageData.faqs.map((faq, i) => (
                    <AccordionItem key={i}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h3>{faq.question}</h3>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "CoviDNA | Advanx Health",
    description: "A coronavirus report to evaluate your infection risk from a genetic level.",
    image: "https://www.advanxhealth.com/img/covidna/covidna-title.png",
    url: "https://www.advanxhealth.com/covidna",
  },
  coronaviruses : [
    {
      title: 'COVID-19',
      name: 'SARS-CoV-2',
      description: 
        'This is a newly discovered disease in 2019 and falls under the coronavirus family. It is an infectious disease that can cause mild to moderate respiratory illness, however if u have comorbidities or underlying diseases you more likely develop the more severe form of the disease.'
    },
    {
      title: 'MERS',
      name: 'MERS-CoV',
      description: 
        'The beta coronavirus that caused the Middle East Respiratory Syndrome.<br/><br/>Middle East respiratory syndrome (MERS) was first identified in 2012. The common symptoms for MERS is fever, cough and shortness of breath'
    },
    {
      title: 'SARS',
      name: 'SARS-CoV',
      description: 
        'The beta coronavirus that causes severe acute respiratory syndrome, discovered in 2002. This disease has caused close to 8000 cases in 26 counties and 700 over deaths. It is highly contagious and can cause a life threathening form of pneumonia. There have been no SARS cases reported anywhere in the world since 2004.'
    }
  ],
  faqs: [
    {
      question: "Will this test diagnose my current COVID-19 status?",
      answer:
        "No, this is not a diagnostic test. This will not tell your current COVID-19 status. Please take the RT-PCR test or RTK Antigen test to confirm your COVID-19 status. You may book for a drive-thru RT-PCR test <a href='https://myscreening.co/' class='text-primary'>here</a> or search for other locations <a href='https://findmytest.advanxhealth.com/' class='text-primary'>here</a>.",
    },
    {
      question: "If my results show I am at high risk and my severity level is severe(high), will that mean I will definitely get infected with COVID-19?",
      answer:
        "No, your results with this test will only show your risk and severity level upon exposure to the coronaviruses. It will not provide a confirmation that you will get infected with the COVID-19. However, whatever your results might be, we highly encourage you to practice all safety precautions and prevention methods as stated by your health authority.<br/><br/>Be sure to wear a mask when you go out, avoid crowded places, practice social distancing and wash your hands frequently.",
    },
    {
      question: "I came into close contact with a COVID-19 positive patient, can I do this test to know if I am infected?",
      answer:
        "No. Unfortunately, this is not a diagnostic test. The results of this test will not be able to identify your current COVID-19 status. Please do the RT-PCR test or RTK Antigen test to confirm your Covid 19 status. You may book for a drive-thru RT-PCR test <a href='https://myscreening.co/' class='text-primary'>here</a> or search for other locations <a href='https://findmytest.advanxhealth.com/' class='text-primary'>here</a>.",
    },
    {
      question: "How can I purchase this report?",
      answer:
        "This report will be available to all customers who purchase any of our DNA Explorer packages (i.e. Prime, Carrier, Personal).",
    },
    {
      question: "Can I purchase this report on its own?",
      answer:
        "Unfortunately, this report is only provided to our customers who have done their DNA tests with us. Hence, you will need to purchase any one of our DNA Explorer packages in order to obtain this report.",
    },
    {
      question: "My results showed I am at low risk and mild severity, does that mean I can go out freely and visit people who are at high risk (like my elderly parents and my young kids)?",
      answer:
        "No matter what your results are, it is highly recommended that you should practice the necessary safety precautions and follow all prevention methods as stated by your health authority. Especially when visiting elderly parents and young children, please take extra precaution to reduce your chances of getting infected and/or infecting others. Low risk and mild (severity level) does not mean you never get infected by the coronaviruses, you can still get infected if you are exposed to the virus.<br/><br/>Be sure to wear a mask when you go out, avoid crowded places, practice social distancing and wash your hands frequently. Please be advised to stay home if you do not have any urgent matters to attend to outside.",
    },
    {
      question: "What can I gain from this report?",
      answer:
        "This report will enable you to understand your risk levels in contracting the coronaviruses upon virus exposure. However, this cannot be used as a medical cert. It is just a report to gain more understanding on how your genetics can predispose to the risk of contracting the coronaviruses upon virus exposure.<br/><br/>We would like to strongly emphasize that this report is based on the current research and is subject to change upon more research done in this field. We believe by providing what is currently available, our customers will benefit from it and be able to get some understanding on their genetic risk factor.  ",
    },
  ],
}
